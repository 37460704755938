import { Route, Switch } from "react-router-dom";
import Account from "../../pages/Account/Account";
import CompanyRates from "../../pages/CompanyRates/CompanyRates";
import Home from "../../pages/Home/home";
import RatesProcess from "../../pages/RatesProcess/RatesProcess";
import Companies from '../../pages/Companies/Companies';
import Users from "../../pages/Users/users";
import Balance from "../../pages/Balance/Balance";
import Contact from "../../pages/Contact/Contact";
import Campaing from "../../pages/Campaing/Campaing";
import CampaignReports from "../../pages/CampaignReports/CampaignReports";
import { useState, useEffect } from "react";
import HistoryBalance from "../../pages/HistoryBalance/HistoryBalance";
import CampaignShortlinks from "../../pages/CampaignShortlinks/CampaignShortlinks";
import CampaignWhatsapp from "../../pages/CampaignWhatsapp/CampaignWhatsapp";
import Contacts from "../../pages/Contacts/Contacts";
import HistoryContacts from "../../pages/HistoryContacts/HistoryContacts";
import ShortlinksReport from "../../pages/ShortlinksReport/ShortlinksReport";
import HistoryTemplates from "../../pages/HistoryTemplates/HistoryTemplates";
import CampaingVoz from "../../pages/CampaignVoz/CampaignVoz";
import ApiKeys from "../../pages/ApiKeys/ApiKeys";
import SmsApiKeysDocumentation from "../../pages/SmsApiKeysDocumentation/SmsApiKeysDocumentation";
import HistoryCampaign from "../../pages/HistoryCampaign/HistoryCampaign";
import HistoryBalanceCarrier from "../../pages/HistoryBalanceCarrier/HistoryBalanceCarrier";
import WebHookUrlMO from "../../pages/WebHookUrlMO/WebHookUrlMO";
import WhatsAppBusinessChannel from "../../pages/WhatsApp/WhatsAppBusinessChannel/WhatsAppBusinessChannel";
import WhatsAppTemplates from "../../pages/WhatsApp/WhatsAppTemplates/";
import BulkWhatsApp from "../../pages/WhatsApp/BulkWhatsApp/BulkWhatsApp";
import CreateWhatsAppTemplate from "../../pages/WhatsApp/WhatsAppTemplates/Create/Create";

const Routers = ({data}) => {

  const components = [
    { name: "Home", component: Home },
    { name: "Account", component: Account },
    { name: "RatesProcess", component: RatesProcess },
    { name: "CompanyRates", component: CompanyRates },
    { name: "Companies", component: Companies },
    { name: "Users", component: Users },
    { name: "Balance", component: Balance },
    { name: "HistoryBalance", component: HistoryBalance },
    { name: "HistoryBalanceCarrier", component: HistoryBalanceCarrier },
    { name: "Contact", component: Contact },
    { name: "Campaign", component: Campaing },
    { name: "CampaignReports", component: CampaignReports },
    { name: "CampaignShortlinks", component: CampaignShortlinks },
    { name: "CampaignRcs", component: null },
    { name: "CampaignWhatsapp", component: BulkWhatsApp },
    { name: "Contacts", component: Contacts },
    { name: "HistoryContacts", component: HistoryContacts },
    { name: "ShortlinksReport", component: ShortlinksReport },
    { name: "HistoryTemplates", component: HistoryTemplates },
    { name: "CampaingVoz", component: CampaingVoz },
    { name: "ApiKeys", component: ApiKeys },
    { name: "SmsApiKeysDocumentation", component: SmsApiKeysDocumentation },
    { name: "HistoryCampaign", component: HistoryCampaign },
    { name: "WebHookUrlMO", component: WebHookUrlMO },
    { name: "WhatsAppTemplates", component: WhatsAppTemplates },
    { name: "CreateWhatsAppTemplate", component: CreateWhatsAppTemplate },
    { name: "WhatsAppBusinessChannel", component: WhatsAppBusinessChannel },
    { name: "BulkWhatsApp", component: BulkWhatsApp },
    { name: "RCSChannel", component: null },
    { name: "RCSAgent", component: null },
  ]

  const [dataRoute, setDataRoute] = useState([])

  useEffect(() => {
    if(data) {
      setDataRoute(data);
    }
  }, [data])

  const render = () => {
    return dataRoute.length == 0 ? <></> : dataRoute.map(item => {
      return <Route key={item.id} path={item.path} exact={item.component === "RCSChannel"} component={getComponent(item.component)} />
    })
  }

  const getComponent = (name) => {
    let result = components.find(x => x.name == name);
    return result ? result.component : <div>Not Found</div>
  }

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      {render()}
      <Route path="*" component={Home} />
    </Switch>
  );
}
 
export default Routers;